/* You can add global styles to this file, and also import other style files */
@import "primeflex/primeflex.css";

body {
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background-color: #121212;
}

.p-column-title {
    font-weight: normal;
    color: var(--gray-200);
}

.p-button.p-button-xs {
    padding: 0.25rem 0.45rem;
}


.table-cell {
    display: table-cell !important;
}

@media screen and (min-width: 576px) {
    .sm\:table-cell {
        display: table-cell !important;
    }
}

@media screen and (min-width: 769px) {
    .md\:table-cell {
        display: table-cell !important;
    }
}

@media screen and (min-width: 992px) {
    .lg\:table-cell {
        display: table-cell !important;
    }
}

@media screen and (min-width: 1200px) {
    .xl\:table-cell {
        display: table-cell !important;
    }
}

.field > label {
    margin-bottom: 0.25rem;
}

.p-menuitem-icon {
    font-size: 1rem;
}

.pointer {
    cursor: pointer;
}


.p-datatable.blue .p-datatable-tbody > tr {
    background: var(--blue-200);
    color: var(--gray-900);
}
.p-datatable.blue .p-datatable-thead > tr > th {
    background: var(--blue-700);
    color: var(--gray-100);
}

.p-steps .p-steps-item:before {
    margin-top: -0.75rem;
}

.p-calendar-w-btn .p-datepicker-trigger {
    padding: 0;
}